const Home = () => import('./components/pages/Home.vue');
const Dashboard = () => import('./components/pages/Dashboard.vue');
const Temperatures = () => import('./components/pages/Temperatures.vue');
const Users = () => import('./components/pages/Users.vue');
const Settings = () => import('./components/pages/Settings.vue');
// const PageNotFound = () => import('./components/pages/PageNotFound.vue');

export default [
  {
    name: 'login',
    path: '/login',
    component: Home,
    meta: { redirectIfAuthenticated: true },
  },
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { redirectIfAuthenticated: true },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    name: 'temperatures',
    path: '/temperatures',
    component: Temperatures,
    meta: {
      requiresAuth: true,
      permission: 'is_admin',
    },
  },
  {
    name: 'users',
    path: '/users',
    component: Users,
    meta: {
      requiresAuth: true,
      permission: 'is_admin',
    },
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      permission: 'is_admin',
    },
  },
];
