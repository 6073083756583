<template>
  <div
    id="nav"
    v-click-outside="close"
    :class="'navigation-list ' + (isToggled ? ' on' : ' off')"
  >
    <a
      id="navigation-toggle"
      ref="toggleButton"
      href="#"
      :class="'toggle' + (isToggled ? ' on' : ' off')"
      @click="toggle"
    >
      <icon
        v-if="isToggled"
        key="isOpen"
        name="fas fa-times"
      />
      <icon
        v-else
        key="isClosed"
        name="fas fa-bars"
      />
    </a>
    <div
      v-if="isToggled"
    >
      <div
        v-if="isLoggedIn"
        key="logged-in"
        class="flex flex-col"
      >
        <router-link
          id="nav-dashboard"
          :to="{name: 'dashboard'}"
          class="px-3 py-2"
        >
          <icon
            name="fas fa-thermometer-half"
            container-class="navigation-icon-container"
          />Your Temperature
        </router-link>
        <router-link
          v-if="user.role.is_admin"
          id="nav-temperatures"
          :to="{name: 'temperatures'}"
          class="px-3 py-2"
        >
          <icon
            name="fas fa-thermometer-full"
            container-class="navigation-icon-container"
          />Temperatures
        </router-link>
        <router-link
          v-if="user.role.is_admin"
          id="nav-users"
          :to="{name: 'users'}"
          class="px-3 py-2"
        >
          <icon
            name="fas fa-users"
            container-class="navigation-icon-container"
          /> Users
        </router-link>
        <router-link
          v-if="user.role.is_admin"
          id="nav-settings"
          :to="{name: 'settings'}"
          class="px-3 py-2"
        >
          <icon
            name="fas fa-cog"
            container-class="navigation-icon-container"
          /> Settings
        </router-link>
        <a
          id="logout"
          href="#"
          class="px-3 py-2"
          @click.prevent="$emit('logout')"
        >
          <icon
            name="fas fa-sign-out-alt"
            container-class="navigation-icon-container"
          />Logout
        </a>
      </div>
      <div
        v-else
        key="not-logged-in"
        class="flex flex-col"
      >
        <router-link
          id="nav-home"
          :to="{name: 'home'}"
          class="px-3 py-2"
        >
          <icon
            name="fas fa-home"
            container-class="navigation-icon-container"
          />Home
        </router-link>
      </div>
      <div
        key="nav-extra-links"
        class="flex flex-col px-3 py-2"
      >
        <div id="nav-extra-links" />
      </div>
    </div>
  </div>
</template>

<script>
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons/faThermometerHalf';
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons/faThermometerFull';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import vClickOutside from 'click-outside-vue3';
import Icon from './Icon.vue';

library.add(
  faBars,
  faTimes,
  faHome,
  faThermometerHalf,
  faThermometerFull,
  faSignOutAlt,
  faUsers,
  faCog,
);

dom.i2svg();
dom.watch();

export default {
  name: 'Navigation',
  components: {
    Icon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manage: false,
    };
  },
  methods: {
    can(permission) {
      return this.user.role[permission];
    },
    toggle() {
      this.$emit('update:isToggled', !this.isToggled);
    },
    close() {
      this.$emit('update:isToggled', false);
    },
  },
};
</script>

<style scoped lang="scss">
  .navigation {
    &-list {
      @apply text-gray-800 text-lg px-4 py-2 absolute z-50;
      background: transparent;
      top: 0;
      left: 0;
      @screen md {
        @apply bg-white border-r border-gray-200 pt-4 border-b-0 relative mt-12;
        top: auto;
        left: auto;
      }
      @screen lg {
        @apply mt-16;
        }
      &.on {
        @apply flex-col justify-end bg-white border-r border-b border-solid border-gray-200;
        @screen md {
          @apply w-1/2 flex-col justify-end border-b-0;
        }
        @screen lg {
          @apply w-1/4;
        }
        @screen xl {
          @apply w-1/5;
        }
      }
      &.off {
        @apply flex-shrink;
      }
      a {
        @apply text-gray-700 no-underline text-left rounded-full
        py-4 px-4 font-bold transition duration-300 ease-in-out;
        &:visited {
          @apply text-gray-700 no-underline;
        }
        &:hover, &:active {
          @apply text-black bg-blue-200;
        }
        &.router-link-active {
          @apply relative font-bold text-green-500 bg-white cursor-default;
          &::after {
            @apply absolute inset-x-0 bottom-0 mb-1 h-1 bg-blue-200 mx-3;
            content: "";
          }
          &:hover, &:active, &visited {
            @apply bg-green-200 bg-white;
          }
        }
        &.toggle {
          @apply w-10 h-10 p-0 m-0 leading-10 text-center block;
        }
      }
    }
  }
</style>
